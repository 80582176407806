import * as React from 'react'
import CalculatorPage from '../../components/calculatorPage/calculatorPage'
import InitialValuesProvider from '../../components/provider/initialValueProvider'
import Seo from '../../components/seo'

const StoredCalculation = ({ params }: any) => {
  const storageId = params?.id
  return (
    <InitialValuesProvider storageId={storageId}>
      <CalculatorPage storageId={storageId} />
    </InitialValuesProvider>
  )
}

export const Head = () => <Seo title="Gespeicherte Berechnung" noindex={true} />

export default StoredCalculation
